<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!---BOC:[header]-->
    <BreadHeader
      :title="`Detach ${childModel.name.singular} from ${parentModel.name.singular}`"
    ></BreadHeader>
    <!--EOC-->
    <!---BOC:[error]-->
    <AError :api="api" />
    <!--EOC-->
    <!---BOC:[form]-->
    <v-card>
      <v-card-text>
        <span
          >Are you sure you want to detach {{ childModel.name.singular }}
          <b class="black--text">{{ childModelName }}</b> from
          {{ parentModel.name.singular }}
          <b class="black--text">{{ parentModelName }}</b
          >?</span
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
        <v-btn :isLoading="api.isLoading" color="error" @click="api.fetch()">
          Detach
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: {
    childModelName() {
      if (this.childModelData) {
        if (this.childModel.column && this.childModel.column.name)
          return this.childModelData[this.childModel.column.name];
        if (this.childModelData.name) return this.childModelData.name;
      }
      return this.childModelId;
    },
    parentModelName() {
      if (this.parentModelData) {
        if (this.parentModel.column && this.parentModel.column.name)
          return this.parentModelData[this.parentModel.column.name];
        if (this.parentModelData.name) return this.parentModelData.name;
      }
      return this.parentModelId;
    },
    ...mapState({
      services: (state) => state.service.data.services,
    }),
  },
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[apiReadIntermediate]
    apiReadIntermediate: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[childModel]
    childModel: new Model(),
    childModelData: null,
    //EOC
    //BOC:[parentModel]
    parentModel: new Model(),
    parentModelId: null,
    parentModelData: null,
    //EOC
    //BOC:[intermediateModel]
    intermediateModel: new Model(),
    intermediateModelId: null,
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
  }),
  created() {
    //BOC:[parentModel]
    this.parentModel.getByKey(this.$route.params.parentModelKey);
    this.parentModelId = this.$route.params.parentModelId;
    //EOC
    //BOC:[childModel]
    this.childModel.getByKey(this.$route.params.childModelKey);
    //EOC
    //BOC:[intermediateModel]
    this.intermediateModel.getByKey(this.$route.params.intermediateModelKey);
    this.intermediateModelId = this.$route.params.intermediateModelId;
    //EOC
    //BOC:[service]
    if (this.parentModel.serviceKey)
      this.service.set("key", this.parentModel.serviceKey);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumb.addForOperationChildDetach({
      services: this.services,
      parentModel: this.parentModel,
      parentModelId: this.parentModelId,
      childModel: this.childModel,
      intermediateModel: this.intermediateModel,
      intermediateModelId: this.intermediateModelId,
    });
    //EOC
    //BOC:[apiReadIntermediate]
    this.apiReadIntermediate.setUrl(
      `${this.$service[this.service.key]}/v1/en/console/model/${
        this.intermediateModel.key
      }/${this.intermediateModelId}`
    );
    this.apiReadIntermediate.setCallbackCompleted((resp) => {
      this.parentModelData =
        resp[this.$_ucfirst(this.$route.params.parentModelKey)];
      this.childModelData =
        resp[this.$_ucfirst(this.$route.params.childModelKey)];
    });
    this.apiReadIntermediate.fetch();
    //EOC
    //BOC:[api]
    this.api.setMethod(`POST`);
    this.api.setUrl(
      `${this.$service[this.intermediateModel.serviceKey]}/v1/en/console/model/${
        this.intermediateModel.key
      }/${this.intermediateModelId}/deleteHard`
    );
    this.api.setParams({
      byForce: true,
    });
    this.api.setCallbackCompleted(() => {
      this.$router.push({
        name: "PageServiceModelRead",
        query: {
          tab: this.intermediateModel.key,
        },
        params: {
          serviceKey: this.parentModel.serviceKey,
          modelKey: this.parentModel.key,
          modelId: this.parentModelId,
        },
      });
      this.$store.dispatch("showMessage", "Detached successfully.");
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>